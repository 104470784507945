import React from "react";
import { useState } from 'react';

const Avatar = ({ user, enablePersonalization }) => {
  const [isImageError, setIsImageError] = useState(false);

  if (!enablePersonalization || !user.avatar.thumbnail || isImageError) {
    return null; // Display nothing if personalization is disabled, no thumbnail exists, or the image fails
  }

  return (
    <div className="relative">
      <img
        className="h-20 w-20 rounded-full border-4 border-white shadow-lg"
        src={user.avatar.thumbnail}
        alt={user.name || ""}
        onError={() => setIsImageError(true)} // Hide the image on error
      />
      <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
    </div>
  );
}

export default Avatar;