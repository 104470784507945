import React from "react";
import PropTypes from "prop-types";

// import "./App.scss";
import "../../index.css";


const App = (props) => <div>{props.children}</div>;

App.propTypes = {
  children: PropTypes.any,
};

export default App;
