import React, { useEffect, useState } from "react";
import { usePapaParse } from "react-papaparse";
import "./CSVRender.scss";

const CSVRender = ({ url }) => {
  const { readRemoteFile } = usePapaParse();
  const [headers, setHeaders] = useState([]);
  const [data, setData] = useState([]);

  const initConfig = () => {
    readRemoteFile(url, {
      complete: (results) => {
        setHeaders(results.data[0]);
        setData(results.data.slice(1));
      },
    });
  };
  
  useEffect(() => {
    initConfig();
  }, []);

  return (
    <div className="csv-render-container">
      <table className="csv-render-table">
        <thead className="csv-header">
          <tr>
            {headers.map((hd, idx) => (
              <th key={`header_cell_${idx}`}>
                <div className="header-csv-cell">{hd}</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="csv-body">
          {data.map((csvData, index) => (
            <tr key={`row_${index}`} className="csv-row">
              {csvData.map((cd, idx) => (
                <td key={`cell_${index}_${idx}`} className="csv-cell">
                  <div className={`csv-cell-box${idx === 0 ? " first-cell" : ""}`}>{cd}</div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CSVRender;
