import React from 'react'
import { useParams } from "react-router-dom"
import axios from "axios"
import './PasswordCheck.scss'

export default function PasswordCheck(props) {
  const [password, setPassword] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState('')

  const params = useParams()

  const onCancel = () => {

  }

  const onSubmit = async () => {
    try {
      const configOptions = {
        headers: {
          "Content-Type": "application/json",
        },
        crossdomain: true,
      };

      const shareAPIUrl = `${process.env.REACT_APP_API_SERVER_BASE_URL}/users/v1/shares/${params.shareId}?password=${password}`;
      const shareResponse = await axios.get(shareAPIUrl, configOptions);
      const shareInfo = shareResponse.data.data;

      if (shareInfo.shortUrl) {
        location.href = `${shareInfo.shortUrl}?sid=${params.shareId}`
      } else {
        setErrorMessage('Password is incorrect. Please input correct password.')
      }
    } catch (error) {
      console.log('get shares error_________________________')
      console.log(error)
      setErrorMessage('Password is incorrect. Please input correct password.')
    }
  }

  return (
    <div className="password-check-wrapper">
      <div className="brand-box">
        <div
          className="custom-background"
          style={{ backgroundColor: '#2020FF' }}
        />
        <div className="triangle1" style={{ backgroundColor: '#2020FF' }} />
        <div className="triangle2" />
        <div className="triangle3" />
      </div>

      <div className="site-content-wrapper">
        <div className="company-logo">
          <div className="logo-box"></div>
        </div>
        <div className="title-section">
          <div className="avatar-box">
            <div className="avatar-circle"></div>
          </div>
          <div className="title-box">
            <div className="site-description"></div>
          </div>
        </div>
        <div className="collections-container">
        </div>
      </div>

      <div className="password-input-container">
        <div className="password-form">
          <div className="form-title"></div>
          <div className="form-content">
            <div className="input-label">Enter Password</div>
            <input
              value={password}
              onChange={e => {
                setPassword(e.target.value)
                setErrorMessage('')
              }}
              placeholder="********"
              type="password"
            />
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <div className="form-actions">
              <div className="form-button" onClick={onCancel}>Cancel</div>
              <div className="form-button" onClick={onSubmit}>Ok</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
