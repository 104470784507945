import React from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import BeatLoader from "react-spinners/BeatLoader";
import CSVRender from "./CSVRender";
import "./CollectionItem.scss";

const DEFAULT_BRANDING_COLOR = "#47c1cc";

const DownloadButton = ({url}) => (
  
  <div class="mt-4 flex justify-center mb-8">
    <a href={url} className="inline-block" download>
      <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
        <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"></path>
        </svg>
        <span class="text-sm">Download</span>
      </button>
    </a>
  </div>
);

const contentItem = (ext, url) => {
  let mediaFile;
  let isAudio;
  switch (ext.toLowerCase()) {
    case "wav":
    case "mp3":
    case "m4a":
    case "aac":
    case "flac":
      return (
        <div class="relative">
          <div className="mt-8">
            <div webgl="true" className="item-content">
              <div className="item-audio-container">
                <audio controls>
                  <source src={url} />
                  Not supported by your browser
                </audio>
              </div>
            </div>
          </div>
          <DownloadButton url={url} />
        </div>
      );
    case "csv":
      return (
        <div class="relative">
          <div className="mt-8">
            <div webgl="true" className="item-content">
              <div className="item-full item-docx">
                <CSVRender url={url} />
              </div>
            </div>
          </div>
          <DownloadButton url={url} />
        </div>
      );
    case "doc":
    case "docx":
    case "pptx":
    case "xlsx":
    case "xls":
      return (
        <div class="relative">
          <div className="mt-8">
            <div class="item-content">
              <div webgl="true" className="item-full item-docx">
                <iframe
                  src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}`}
                  className="item-docx"
                  frameBorder="0"
                  title="MS Office"
                ></iframe>
              </div>
            </div>
          </div>
          <DownloadButton url={url} />
        </div>
      );
    case "jpg":
    case "jpeg":
    case "png":
    case "bmp":
    case "svg":
      return (
        <div webgl="true" className="item-content">
          <div className="item-img--container">
            <img src={url} className="item-img--content" alt={`${ext} file`} />
          </div>
        </div>
      );
    case "pdf":
      return (
        <div
          className="_df_book"
          height="100%"
          webgl="true"
          backgroundcolor="transparent"
          source={url}
          id="df_manual_book"
          style={{ height: "100% important" }}
        />
      );
    case "txt":
      fetch(url)
        .then((res) =>
          res.text().then((text) => {
            return <div>{text}</div>;
          })
        )
        .catch((err) => console.log(err));
      break;
    case "mov":
    case "avi":
    case "mp4":
    case "webm":
      // mp4 might be audio too, but we'll assume it's video
      return (
        <div webgl="true" className="item-content">
          <div className="item-video-container">
            <video src={url} controls autoPlay muted className="item-full"></video>
          </div>
        </div>
      );
    case "html":
      return (
        <iframe
          src={url}
          width="auto"
          height="auto"
          frameBorder="0"
          title="mpeg"
          className="item-html-container"
        ></iframe>
      );
    case "wma":
    default:
      window.location.href = url
      return (
        <div class="relative">
          <div className="mt-8">
            
          </div>
          <DownloadButton url={url} />
        </div>
      );
  }
};



const CollectionItem = (props) => {
  const [brandingOption, setBrandingOption] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [content, setContent] = React.useState(null);
  const [curItemType, setCurItemType] = React.useState(null);

  const routeParams = useParams();

  const initConfigurations = async () => {
    const itemAPIUrl = `${process.env.REACT_APP_API_SERVER_BASE_URL}/collections/v1/items?id=${routeParams.itemId}&branding=true`;
    const configOptions = {
      headers: {
        "Content-Type": "application/json",
      },
      crossdomain: true,
    };

    const response = await axios.get(itemAPIUrl, configOptions);
    const params = response.data.data;

    let ext = params.fullUrl.split(".");

    if (params.ext && params.ext === "html") {
      setContent(contentItem(params.ext, params.fullUrl));
    } else {
      setContent(contentItem(ext[ext.length - 1], params.fullUrl));
    }

    setCurItemType(params.ext)

    // Load DFLIP
    // eslint-disable-next-line no-undef
    $("#df_manual_book").flipBook(params.fullUrl);

    setBrandingOption(params.branding);
    setIsLoading(false);
  };

  React.useEffect(() => {
    if (window.DFLIP) {
      initConfigurations();
    }
  }, [window.DFLIP]);

  return (
    <div className={`item-viewer-container ${curItemType || ''}`}>
      {isLoading && (
        <div className="loading-wrapper">
          <BeatLoader size={20} margin={4} color="#36D7B7" />
        </div>
      )}
      {!isLoading && (
        <div className="brand-box">
          <div
            className="custom-background"
            style={{
              backgroundColor: brandingOption.color || DEFAULT_BRANDING_COLOR,
            }}
          />
          <div
            className="triangle1"
            style={{
              backgroundColor:
                brandingOption.color ||
                brandingOption.color ||
                DEFAULT_BRANDING_COLOR,
            }}
          />
          <div className="triangle2" />
          <div className="triangle3" />
        </div>
      )}
        <div className="item-logo">
          {brandingOption.logo && <img src={brandingOption.logo} alt="logo" />}
        </div>
      {curItemType && content}
      {/* <!-- Footer --> */}
      <footer class="bg-transparent mt-56 py-8">
        <div class="text-right mr-4">
            <p class="text-gray-500 text-xs"><i>Powered by <a href="https://www.contentcamel.io"><strong>Content Camel</strong></a></i></p>
        </div>
      </footer>
    </div>
    
  );
};

export default CollectionItem;
