import React from 'react';
import axios from "axios";
import './PDFViewer.scss';

const PDFViewer = props => {
  let color = "#47c1cc";
  const [enablePersonalization, setEnablePersonalization] = React.useState(true);
  const [sidQuery, setSidQuery] = React.useState(null);
  const [collection, setCollection] = React.useState({
    items: []
  });

  // //////////////////////////////////////////
  // Styles

  const triangle1Style = {
    backgroundColor: color,
    opacity: 0.8,
  };
  const triangle2Style = {
    backgroundColor: color,
    opacity: 0.5,
  };
  const triangle3Style = {
    backgroundColor: color,
    opacity: 0.4,
  };

  const initSettings = async () => {
    const { userId, collectionId } = props.match.params;

    let searchText = props.history.location.search;
    searchText = searchText.replace('?', '');
    const searchParams = searchText.split('&');
    let newSidQuery = null;

    let searchValues = null;
    if (searchParams.length > 0) {
      searchValues = searchParams[0].split('=');
    }

    if (searchValues[0] === 'sid') {
      newSidQuery = searchParams[0];
    }

    if (!userId || !collectionId) {
      gotoNotFound();
    }

    const collectionAPIUrl = `${process.env.REACT_APP_API_SERVER_BASE_URL}/collections/v1/items?ownedBy=${userId}&collectionId=${collectionId}`;
    const configOptions = {
      headers: {
        "Content-Type": "application/json"
      },
      crossdomain: true
    };

    try {
      const response = await axios.get(collectionAPIUrl, configOptions);
      if (response.data.data.deletedAt) {
        gotoRemovedSite();
      }

      process.env.OG_TITLE = response.data.data.title;
      // turn off avatar and contact info if flagged off for this site
      const { enablePersonalization } = response.data.data;
      if (enablePersonalization === false) {
        setEnablePersonalization(false);
      }

      setCollection(response.data.data);
      setSidQuery(newSidQuery);
    } catch (error) {
      console.log("error: ", error.message);
      gotoNotFound();
    }
  }

  const gotoNotFound = () => {
    window.location.assign(
      `${process.env.REACT_APP_API_CLIENT_BASE_URL}/not-found`
    );
  };

  const gotoRemovedSite = () => {
    window.location.assign(
      `${process.env.REACT_APP_API_CLIENT_BASE_URL}/removed-site`
    );
  };

  React.useEffect(() => {
    initSettings();
  }, []);

  if (collection.branding) {
    color = collection.branding.color;
  }

  return (
    <div className="pdf-viewer-container relative overflow-hidden">
      <div className="brand-box relative w-full h-[500px]">
        {/* Custom Background */}
        <div
          className="custom-background absolute w-full h-[120px] top-[-60px] skew-y-[-16deg] overflow-hidden origin-left opacity-90"
          style={{ backgroundColor: color }}
        ></div>

        {/* Triangle 1 */}
        <div
          className="triangle1 absolute w-full h-full skew-y-[-8deg] overflow-hidden origin-left"
          style={triangle1Style}
        ></div>

        {/* Triangle 2 */}
        <div
          className="triangle2 absolute h-[80px] right-[120px] bottom-0 left-0 skew-y-[-8deg] overflow-hidden origin-left"
          style={triangle2Style}
        ></div>

        {/* Triangle 3 */}
        <div
          className="triangle3 absolute h-[100px] right-[40px] bottom-[calc(60%*-0.06)] left-2/4 skew-y-[-8deg] overflow-hidden origin-left"
          style={triangle3Style}
        ></div>
      </div>

      {/* PDF Container */}
      <div
        className="_df_book"
        height="100%"
        webgl="true"
        backgroundcolor="transparent"
        source={window.location.search.replace("?url=", "")}
        id="df_manual_book"
      />
    </div>
  );
}

export default PDFViewer;
